/* eslint-disable no-unused-vars */
import { UserType } from './UserTypes'
import { EntityAccountType } from './AccountsTypes'
import { ChartOfAccountType, PlatformType } from './EntityTypes'
import { AccountType, CurrencyItemType, SourceWalletType } from './ReportsTypes'
import { JournalItemType, JournalTypeItemType, TransactionItemType } from './TransactionsTypes'

export enum ReconciliationTaskDataSetEnum {
  TRANSACTION = 'TRANSACTION',
  LEDGER = 'LEDGER'
  // 可以在这里添加更多的 dataSetType
}

export interface ReconciliationToleranceType {
  sideAField: string
  sideBField: string
  lowerLimit: number
  upperLimit: number
  type: string
  limitUnit: string
}

export interface ReconciliationTaskRuleItemType {
  reconciliationRuleId: string
  name: string
  priority: number
  isLock: boolean
  toleranceList: ReconciliationToleranceType[]
  isEnabled: boolean
  matchCount?: number
  transactionCount?: number
  amountFC?: number
}

export interface ReconciliationTaskRuleListType {
  list: ReconciliationTaskRuleItemType[]
  totalCount: number
}

export interface ReconciliationTaskDetailType {
  reconciliationTaskId: string
  entityId: string
  name: string
  dataSetTypeA: ReconciliationTaskDataSetEnum
  dataSetNameA: string
  dataSetTypeB: ReconciliationTaskDataSetEnum
  dataSetNameB: string
  fromDatetimeStr: string
  toDatetimeStr: string
  fromDatetime: string
  toDatetime: string
  status: string
  taskNo: string
  userId: string
  syncDatetime: string | null
  dataStatus: string
}

export interface ReconciliationTaskListType {
  list: ReconciliationTaskDetailType[]
  totalCount: number
}

export interface auxiliaryItemType {
  auxiliaryCodeId: string
  type: string
  name: string
  entityAccount?: EntityAccountType
}

export interface ReconciliationLedgerType {
  generalLedgerId: string
  chartOfAccount: ChartOfAccountType
  datetime: string
  auxiliaryValues: auxiliaryItemType[]
  journal: {
    journalType: {
      journalTypeId: string
      entityId: string
      code: string
      name: string
      journalNoPrefix: string
      status: string
      isTrxFee: null
    }
    journalActivityId: string
    journalNo: string
  }
  currency: CurrencyItemType
  debit: number | null
  credit: number | null
  openingBalanceFC: number
  debitFC: number | null
  creditFC: number | null
  closingBalanceFC: number
}
export interface ReconciliationMatchedTransactionItemType extends TransactionItemType {
  transactionId: string
  entityId: string
  baseTransactionId: string | null
  platformId: string
  dwTxHash: string | null
  type: string
  journalStatus: string
  dataBatchId: string | null
  integrationId: string | null
}

export interface ReconciliationFilterDataType {
  matchNo?: string
  ledgerJournalNo?: string
  ledgerReferenceNo?: string
  transactionTxHash?: string
  reconciliationReasonId?: string
  reconciliationRuleId?: string
  userId?: string
}

export interface ReconciliationFilterItemType {
  entityId: string
  total: number
  data: ReconciliationFilterDataType
}

export interface ReconciliationFilterTypeInfo {
  type: string
  list: ReconciliationFilterItemType[]
}

export interface ReconciliationTaskMatchedByItemType {
  reconciliationRuleList: [
    {
      reconciliationRuleId: string
      name: string
    }
  ]
  userList: [
    {
      userId: string
      name: string
    }
  ]
}

export interface ReconciliationDataSetConditionItemType {
  type: string
  operator: string
  auxiliaryTypeId?: string
  value: Array<any>
}

export interface ReconciliationDataCurrencyItemType {
  currency: string
  logo?: string
  amountTotal: number
}

export interface ReconciliationTaskBalanceItemDetailType {
  name: string
  currencyList: ReconciliationDataCurrencyItemType[]
}

export interface ReconciliationTaskBalanceListItemType {
  currencyList: string[]
  list: ReconciliationTaskBalanceItemDetailType[]
}

export interface ReconciliationTaskDataSetItemDetailType {
  entityId?: string
  reconciliationDataSetId: string
  reconciliationTaskId?: string
  side?: string
  dataType?: string
  name: string
  conditions?: ReconciliationDataSetConditionItemType[]
  reconciliationDataCount?: number
  amountFCTotal: number
  reconciliationDataCurrencyList: ReconciliationDataCurrencyItemType[]
}

export interface ReconciliationTaskDataSetListType {
  sideAList: ReconciliationTaskDataSetItemDetailType[]
  sideBList: ReconciliationTaskDataSetItemDetailType[]
  adjustmentJournal: ReconciliationTaskDataSetItemDetailType
}

export interface ReconciliationTaskReasonType {
  reconciliationReasonId: string
  name: string
}

export interface ReconciliationExplainType {
  reconciliationExplainId: string
  entityId: string
  reconciliationTaskId: string
  side: string
  dataType: string
  dataId: string
  reconciliationMatchId: string
  userId: string
  reconciliationRuleId: string
  description: string
  journalActivityId: string
  reconciliationReasonId: string
}

export interface ReconciliationMatchedDataSetItemType {
  amount: string
  amountFC: string
  contactIdentity: string
  contactPlatform: PlatformType
  contactPlatformId: number
  contactPlatformType: string
  counterpartyId: string
  counterpartyTransformerId: string
  currency: string
  datetime: string
  direction: string
  entityAccount: EntityAccountType
  entityAccountId: string
  entityId: string
  integrationId: string | null
  internalEntityAccount: AccountType | null
  journalActivityId: string
  journalActivityIds: string[]
  journalActivityList: JournalItemType[]
  journalTypeId: string
  journalTypeIds: string[]
  platform: PlatformType
  platformId: string
  platformType: string
  reconciliationMatchJournalTypeList: JournalTypeItemType[]
  source: SourceWalletType
  sourceId: string
  transactionId: string
  transactionRollId: string
  txHash: string
  type: string
  underlyingCurrency: CurrencyItemType
  verifyStatus: string
}

export interface ReconciliationMatchedItemType {
  datetime: string
  reconciliationMatchId: string
  reconciliationTaskId: string
  entityId: string
  matchNo: string
  reconciliationSetId: string
  reconciliationRuleId: string
  userId: string
  chartOfAccountId: string
  entityAccountId: string
  direction: string
  transactionIds: string[]
  ledgerIds: string[]
  createdAt: string
  updatedAt: string
  reconciliationRule: ReconciliationTaskRuleItemType
  user: null | UserType
  transactionList: ReconciliationMatchedTransactionItemType[]
  ledgerList: ReconciliationLedgerType[]
  status: string
  difference?: string
  deletedAt?: string | null
  ledgerJournalNoList?: string
  ledgerReferenceNoList?: string
  reconciliationReasonIds: string
  sideADataIds?: number[]
  sideBDataIds?: number[]
  dataSetAList: ReconciliationMatchedDataSetItemType[]
  dataSetBList: ReconciliationMatchedDataSetItemType[]
  reconciliationTask?: ReconciliationTaskDetailType
}

export interface ReconciliationMatchedListType {
  filteredCount: number
  totalCount: number
  list: ReconciliationMatchedItemType[]
}

export interface ReconciliationMatchedDetailType extends ReconciliationMatchedItemType {
  reconciliationReasonId: string
  reconciliationReason: ReconciliationTaskReasonType
  reconciliationTaskId: string
  reconciliationTask: ReconciliationTaskDetailType
  reconciliationExplainId: string
  journalActivity: JournalItemType | null
  reconciliationExplain: ReconciliationExplainType
  reason?: ReconciliationTaskReasonType
}

export interface DetailsReconciliationTransactionType {
  totalCount: number
  filteredCount: number
  list: ReconciliationMatchedTransactionItemType[]
}

export interface DetailsReconciliationLedgerType {
  totalCount: number
  filteredCount: number
  list: ReconciliationLedgerType[]
}

export interface ReconciliationTaskSummaryType {
  pendingCount: number
  processedCount: number
  dataCount: number
}

export interface ReconciliationReportCurrencyType {
  underlyingCurrency: CurrencyItemType
  amountTotal: string
}

export interface ReconciliationReportBalanceType {
  direction?: string
  name: string
  reconciliationDataCount: number
  reconciliationReasonId?: string
  currencyList: ReconciliationReportCurrencyType[]
}

export interface ReconciliationReportItemType {
  type: string
  list: ReconciliationReportBalanceType[]
}

export interface ReconciliationReportType {
  currencyList: ReconciliationReportCurrencyType[]
  list: ReconciliationReportItemType[]
  name: string
}

export interface ReconciliationReportListType {
  currencyList: CurrencyItemType[]
  list: ReconciliationReportType[]
}

export interface DetailsReconciliationSideTableRequestType {
  type: string
  params: object
  bodyData: object
}

export interface DetailsReconciliationSideTableParamsType extends DetailsReconciliationSideTableRequestType {
  dataSetType: ReconciliationTaskDataSetEnum
}
