import { uniqueId } from 'lodash-es'
import { useAccountStore } from './accounts'
import { useTransactionStore } from './transactions'
import ReconciliationApi from '@/api/ReconciliationApi'
import {
  ReconciliationTaskListType,
  ReconciliationFilterTypeInfo,
  ReconciliationFilterDataType,
  ReconciliationTaskReasonType,
  ReconciliationReportListType,
  ReconciliationTaskDetailType,
  ReconciliationTaskSummaryType,
  ReconciliationTaskDataSetEnum,
  ReconciliationTaskRuleListType,
  DetailsReconciliationLedgerType,
  ReconciliationTaskDataSetListType,
  DetailsReconciliationTransactionType,
  ReconciliationTaskBalanceListItemType,
  ReconciliationTaskDataSetItemDetailType,
  DetailsReconciliationSideTableParamsType,
  DetailsReconciliationSideTableRequestType
} from '#/ReconciliationTypes'
import { JournalFilterItemType, TransactionFilterTypeInfo } from '#/TransactionsTypes'

export const useReconciliationStore = defineStore({
  id: 'reconciliationStore',
  state: () => {
    const reconciliationState: {
      detailLoading: boolean
      reconciliationTaskListType: ReconciliationTaskListType
      reconciliationTaskDetail: ReconciliationTaskDetailType
      reconciliationTaskDataSetList: ReconciliationTaskDataSetListType
      reconciliationTaskBalanceList: ReconciliationTaskBalanceListItemType
      detailsReconciliationLeftSideData: DetailsReconciliationTransactionType
      detailsReconciliationRightSideData: DetailsReconciliationLedgerType
      reconciliationTaskSummary: ReconciliationTaskSummaryType
      reconciliationTaskRuleList: ReconciliationTaskRuleListType
      reconciliationTaskReasonList: ReconciliationTaskReasonType[]
      reconciliationFilterTypeInfo: ReconciliationFilterTypeInfo[]
      reportList: ReconciliationReportListType
      transactionFilterList: TransactionFilterTypeInfo[]
      journalFilterList: JournalFilterItemType[]
      detailsReconciliationRequestData: any
    } = {
      detailLoading: false,
      reconciliationTaskListType: {
        totalCount: 0,
        list: []
      },
      reconciliationTaskDetail: {} as ReconciliationTaskDetailType,
      detailsReconciliationLeftSideData: {
        totalCount: 0,
        filteredCount: 0,
        list: []
      },
      detailsReconciliationRightSideData: {
        totalCount: 0,
        filteredCount: 0,
        list: []
      },
      reconciliationTaskRuleList: {
        totalCount: 0,
        list: []
      },
      reconciliationTaskSummary: {
        pendingCount: 0,
        processedCount: 0,
        dataCount: 0
      },
      reconciliationTaskReasonList: [],
      reconciliationTaskDataSetList: {
        sideAList: [],
        sideBList: [],
        adjustmentJournal: {} as ReconciliationTaskDataSetItemDetailType
      },
      reconciliationTaskBalanceList: {
        currencyList: [],
        list: []
      },
      reconciliationFilterTypeInfo: [],
      reportList: {
        currencyList: [],
        list: []
      },
      transactionFilterList: [],
      journalFilterList: [],
      detailsReconciliationRequestData: []
    }
    return reconciliationState
  },
  actions: {
    /**
     * 获取对账工作列表
     * @param {string} entityId 主体id
     * @param {Object} params 查询参数
     * @property {number} params.page 页码
     * @property {number} params.limit 每页条数
     */
    async fetchReconciliationTaskList(entityId: string, params: object) {
      try {
        const { data } = await ReconciliationApi.getReconciliationTaskList(entityId, params)
        this.reconciliationTaskListType = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对账工作详情
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     */
    async fetchReconciliationTaskDetail(entityId: string, reconciliationTaskId: string) {
      try {
        const { data } = await ReconciliationApi.getReconciliationTaskDetail(entityId, reconciliationTaskId)
        this.reconciliationTaskDetail = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对账工作数据集列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     */
    async fetchReconciliationDataSetList(entityId: string, reconciliationTaskId: string) {
      try {
        const { data } = await ReconciliationApi.getReconciliationTaskDataSetList(entityId, reconciliationTaskId)
        this.reconciliationTaskDataSetList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对账任务详情发生额数据
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     */
    async fetchReconciliationTaskBalanceList(entityId: string, reconciliationTaskId: string) {
      try {
        const { data } = await ReconciliationApi.getReconciliationTaskBalanceList(entityId, reconciliationTaskId)
        this.reconciliationTaskBalanceList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对账工作原因列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     */
    async fetchReconciliationTaskReasonList(entityId: string, reconciliationTaskId: string) {
      try {
        const { data } = await ReconciliationApi.getReconciliationTaskReasonList(entityId, reconciliationTaskId)
        this.reconciliationTaskReasonList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取已对账列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     * @param {Object} params 查询参数
     * @property {number} params.page 页码
     * @property {number} params.limit 每页条数
     */
    async fetchReconciliationMatchList(
      entityId: string,
      reconciliationTaskId: string,
      params: object,
      filterData: object = {}
    ) {
      try {
        const { data } = await ReconciliationApi.getReconciliationMatchList(
          entityId,
          reconciliationTaskId,
          params,
          filterData
        )
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对账明细交易列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     * @param {Object} requestData
     * @param {string} requestData.type 对账明细类型 PENDING｜PROCESSED
     * @param {Object} requestData.params 查询参数
     * @property {number} params.page 页码
     * @property {number} params.limit 每页条数
     * @param {Object} requestData.bodyData
     */
    async fetchReconciliationTrxList(
      entityId: string,
      reconciliationTaskId: string,
      requestData: DetailsReconciliationSideTableRequestType = {
        type: '',
        params: {},
        bodyData: {}
      }
    ) {
      try {
        const { type, params, bodyData } = requestData
        const searchParams = {
          ...params,
          type
        }

        const { data } = await ReconciliationApi.getReconciliationTransactionList(
          entityId,
          reconciliationTaskId,
          searchParams,
          bodyData
        )

        this.detailsReconciliationLeftSideData = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对账明细分类账列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     * @param {Object} requestData
     * @param {string} requestData.type 对账明细类型 PENDING｜PROCESSED
     * @param {Object} requestData.params 查询参数
     * @property {number} requestData.params.page 页码
     * @property {number} requestData.params.limit 每页条数
     * @param {Object} requestData.bodyData
     */
    async fetchReconciliationLedgerList(
      entityId: string,
      reconciliationTaskId: string,
      requestData: DetailsReconciliationSideTableRequestType = {
        type: '',
        params: {},
        bodyData: {}
      }
    ) {
      const { type, params, bodyData } = requestData
      try {
        const searchParams = {
          ...params,
          type
        }

        const { data } = await ReconciliationApi.getReconciliationLedgerList(
          entityId,
          reconciliationTaskId,
          searchParams,
          bodyData
        )
        this.detailsReconciliationRightSideData = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对账明细列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     * @param {Object} data
     * @param {string} data.type 对账明细类型 PENDING｜PROCESSED
     * @param {ReconciliationTaskDataSetEnum} data.dataSetType 数据类型 TRANSACTION｜LEDGER
     * @param {Object} data.params 查询参数
     * @property {number} data.params.page 页码
     * @property {number} data.params.limit 每页条数
     * @param {Object} data.bodyData 查询参数
     */
    async fetchReconciliationSideTableList(
      entityId: string,
      reconciliationTaskId: string,
      data: DetailsReconciliationSideTableParamsType = {
        type: '',
        dataSetType: ReconciliationTaskDataSetEnum.TRANSACTION,
        params: {},
        bodyData: {}
      }
    ) {
      try {
        const { type, dataSetType, params, bodyData } = data
        switch (dataSetType) {
          case 'TRANSACTION':
            return this.fetchReconciliationTrxList(entityId, reconciliationTaskId, { type, params, bodyData })
          case 'LEDGER':
            return this.fetchReconciliationLedgerList(entityId, reconciliationTaskId, { type, params, bodyData })
          default:
            break
        }
        return Promise.reject(new Error('Invalid dataSetType'))
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对账工作概要信息
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     */
    async fetchReconciliationTaskSummary(entityId: string, reconciliationTaskId: string) {
      try {
        const { data } = await ReconciliationApi.getReconciliationTaskSummary(entityId, reconciliationTaskId)
        this.reconciliationTaskSummary = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对账工作规则列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     * @param {Object} params 查询参数
     * @property {number} params.page 页码
     * @property {number} params.limit 每页条数
     */
    async fetchReconciliationTaskRuleList(entityId: string, reconciliationTaskId: string, params: object) {
      try {
        const { data } = await ReconciliationApi.getReconciliationTaskRuleList(entityId, reconciliationTaskId, params)
        this.reconciliationTaskRuleList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 对账明细列表筛选器数据初始化
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     */
    async detailsReconciliationFilterDataInit(entityId: string, reconciliationTaskId: string) {
      try {
        const accountStore = useAccountStore()
        const transactionStore = useTransactionStore()
        const requestList: any = [
          accountStore.fetchEntityAccountList(entityId, { withDeleted: true }),
          this.fetchReconciliationTaskReasonList(entityId, reconciliationTaskId),
          transactionStore.fetchTransactionCurrencyList(entityId),
          transactionStore.fetchJournalTypeList(entityId)
        ]

        const response = await Promise.allSettled(requestList)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 对账明细列表数据初始化
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 对账工作id
     * @param {string} type 对账明细类型 PENDING｜PROCESSED
     * @param {Object} data
     * @param {Object} data.leftSideParams 左侧数据查询参数
     * @param {Object} data.rightSideParams 右侧数据查询参数
     * @param {ReconciliationTaskDataSetEnum} data.dataSetTypeA 左侧数据类型 TRANSACTION｜LEDGER
     * @param {ReconciliationTaskDataSetEnum} data.dataSetTypeB 右侧数据类型
     * @param {Object} data.leftSideBodyData 左侧数据筛选参数
     * @param {Object} data.rightSideBodyData 右侧数据筛选参数
     */
    async detailsReconciliationDataListDataInit(
      entityId: string,
      reconciliationTaskId: string,
      type: string,
      data: any
    ) {
      try {
        const requestList: any = [this.fetchReconciliationTaskSummary(entityId, reconciliationTaskId)]

        const dataSetTypeRequests = {
          [ReconciliationTaskDataSetEnum.TRANSACTION]: (side: string) => {
            this.detailsReconciliationRequestData.push({
              url: `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/transaction`,
              params: side === 'LEFT' ? { ...data?.leftSideParams, type } : { ...data?.rightSideParams, type }
            })
            return this.fetchReconciliationTrxList(entityId, reconciliationTaskId, {
              type,
              params: side === 'LEFT' ? data.leftSideParams : data.rightSideParams,
              bodyData: side === 'LEFT' ? (data?.leftSideBodyData ?? {}) : (data?.rightSideBodyData ?? {})
            })
          },
          [ReconciliationTaskDataSetEnum.LEDGER]: (side: string) => {
            this.detailsReconciliationRequestData.push({
              url: `/entity/${entityId}/reconciliation/task/${reconciliationTaskId}/ledger`,
              params: side === 'LEFT' ? { ...data?.leftSideParams, type } : { ...data?.rightSideParams, type }
            })
            return this.fetchReconciliationLedgerList(entityId, reconciliationTaskId, {
              type,
              params: side === 'LEFT' ? data.leftSideParams : data.rightSideParams,
              bodyData: side === 'LEFT' ? (data?.leftSideBodyData ?? {}) : (data?.rightSideBodyData ?? {})
            })
          }
          // 可以在这里添加更多的 dataSetType 和对应的请求函数
        }

        ;[data.dataSetTypeA, data.dataSetTypeB].forEach((dataSetType: ReconciliationTaskDataSetEnum, index: number) => {
          if (dataSetTypeRequests[dataSetType as ReconciliationTaskDataSetEnum]) {
            requestList.push(
              dataSetTypeRequests[dataSetType as ReconciliationTaskDataSetEnum](index === 0 ? 'LEFT' : 'RIGHT')
            )
          }
        })
        const response = await Promise.allSettled(requestList)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 存储对账模块筛选数据条件
     * @param {string} entityId 主体id
     * @param {ReconciliationFilterDataType} data 过滤器数据
     * @param {string} type 过滤器类型 reconciliationFilterSaveType  MATCHED | REPORT ...
     */
    editReconciliationFilter(entityId: string, data: ReconciliationFilterDataType, currentType = 'MATCHED') {
      const currentSaveInfo = this.reconciliationFilterTypeInfo.find((item) => item.type === currentType)
      const filterLength = Object.keys(data).length
      if (currentSaveInfo) {
        const currentSaveList = currentSaveInfo?.list || []
        const currentIndex = currentSaveList.findIndex((item: any) => item.entityId === entityId)

        if (currentIndex !== -1) {
          if (!filterLength) {
            currentSaveList.splice(currentIndex, 1)
          } else {
            currentSaveList.splice(currentIndex, 1, {
              entityId,
              total: filterLength,
              data
            })
          }
        } else if (filterLength) {
          currentSaveList.push({ entityId, total: filterLength, data })
        }
      } else {
        this.reconciliationFilterTypeInfo.push({
          type: currentType,
          list: [
            {
              entityId,
              total: filterLength,
              data
            }
          ]
        })
      }
    },
    /**
     * 获取对账报告列表
     * @param {string} entityId 主体id
     * @param {string} reconciliationTaskId 主体id
     */
    async fetchReconciliationReportList(entityId: string, reconciliationTaskId: string) {
      try {
        const { data } = await ReconciliationApi.getReconciliationReportList(entityId, reconciliationTaskId)
        // 循环 data中的数据是树形结构的子节点为 list  所以需要循环遍历给每一层里的对象加一个 id用uniqueId生成
        const loop: any = (list: any[], parentData: any = {}) => {
          list.forEach((item, index) => {
            item.id = uniqueId()
            if (!item?.dataSetNameB && !item?.dataSetNameA && !item?.type) {
              item.index = index + 1
              item.parentType = parentData?.type ?? ''
              item.side = parentData?.side ?? ''
              item.dataSetType = parentData?.dataSetType ?? ''
            }
            if (item?.list && item?.list?.length) {
              loop(item.list, item)
            }
          })
        }
        loop(data.list ?? [])
        this.reportList = data
        if (data?.list?.length === 2) {
          this.reportList.list[1].list = []
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    }
  },
  getters: {},
  // 本地化持久储存
  persist: {
    enabled: true,
    strategies: [
      {
        // 默认是全部进去存储 可以选择哪些进入local存储，这样就不用全部都进去存储了
        storage: localStorage,
        paths: [
          'transactionFilterList',
          'journalFilterList',
          'reconciliationTaskDetail',
          'reconciliationFilterTypeInfo'
        ]
      }
    ]
  }
})
